
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface Gateway {
  driver: string;
  key: string;
  name: string;
  email: string;
  phone: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const submitButton = ref<null | HTMLButtonElement>(null);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.account.create"), [
      { title: t("management.account.create") }
    ]);

    const create = reactive<Gateway>({
      driver: '',
      key: '',
      name: '',
      email: '',
      phone: '',
    });

    const resetForm = () => {
      create.driver = '';
      create.key = '';
      create.name = '';
      create.email = '';
      create.phone = '';
    }

    const submitForm = () => {
      if (create.driver == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Driver Name cannot empty',
          message: 'Driver Name cannot empty',
        })

        return;
      }

      if (create.key == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Gateway Key cannot empty',
          message: 'Gateway Key cannot empty',
        })

        return;
      }

      if (create.name == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      if (create.email == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Email cannot empty',
          message: 'Email cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        driver: create.driver,
        key: create.key,
        name: create.name,
        email: create.email,
        phone: create.phone,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/entity/gateway`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Create Status',
            text: 'Success'
          });

          resetForm();
        })
        .catch(({ response }) => {
          showErrorPopup({
            title: response.data.rc,
            text: response.data.message,
            message: response.data.message,
          })
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      create,
      translate,
      submitForm,
      submitButton,
      resetForm
    };
  },
  methods: {}
});
